@import '@root-auth/styles/settings.scss';

.login {
	width: 100%;
	height: 100vh;
	@include flex-column-center;

	&__image {
		width: 130px;
		height: 35px;
		object-fit: cover;
		margin-top: 25px;
	}

	&__form {
		margin-top: 10%;
		max-width: 340px;
		width: 100%;
		margin-bottom: 25px;
		@include flex-column-center;
	}

	&__title {
		font-size: 1.5em;
		font-weight: normal;
		margin-bottom: 70px;
		text-align: center;
	}

	&__fields {
		@include flex-column-center;
		margin-bottom: 30px;
		width: 100%;

		&-input {
			border: 2px solid $black;
			border-radius: 6px;
			background: #fff;
			padding: 10px 0 10px 10px;
			font-size: 0.8em;
			color: $black;
			width: 100%;
			height: 36px;
			transition: all 0.25s ease-in-out;
			margin-bottom: 12px;

			&:focus,
			&:active {
				border: 2px solid $main-blue;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&:disabled {
				background-color: #ddd;

				&:focus,
				&:active {
					border: 2px solid $black;
				}
			}
		}

		&-submit-rules {
			font-size: 12px;
		}

		&-checkbox {
			align-self: flex-start;
			position: relative;

			& > span {
				display: inline-flex;
				align-items: center;
				user-select: none;
				font-size: 12px;

				&::before {
					content: '';
					display: inline-block;
					width: 20px;
					height: 20px;
					flex-shrink: 0;
					flex-grow: 0;
					border: 2px solid $black;
					border-radius: 0.25em;
					margin-right: 0.5em;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 50% 50%;
					transition: all 0.25s ease-in-out;
				}
			}

			& > input {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
			}

			& > input:not(:disabled):not(:checked) + span:hover::before {
				border-color: $main-blue;
			}

			& > input:checked + span::before {
				border-color: $main-blue;
				background-color: $main-blue;
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
			}

			& > input:disabled + span::before {
				background-color: #e9ecef;
			}
		}
	}

	&__actions {
		@include flex-column-center;
		width: 100%;
		margin: -10px 0;

		&-item {
			margin: 10px 0;
		}
	}
	&__helper-text {
		margin-left: 38px;
		margin-right: 14px;
		font-size: 0.75rem;
		text-align: left;
		font-weight: 400;
		line-height: 1.66;
		letter-spacing: 0.03333em;
		width: 100%;
	}
}
.error-message {
	color: #f44336;
}
