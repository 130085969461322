$main-blue: #1BAAF0;
$black: rgba(0, 0, 0, 0.87);

@mixin flex-between-center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin flex-column-center {
	display: flex;
	flex-direction: column;
	align-items: center;
}
